import React, { useState } from "react";
import { Grid, Paper, TextField, Button } from "@mui/material";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const handleSend = async () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: input, sender: "user" },
    ]);
    setInput("");

    try {
      const response = await fetch("https://api.elisiq.com/f-chat-pdf", {
        method: "POST",
        body: JSON.stringify({
          message: input,
          user: { username: "joe", password: "1234" },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log(response);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      const resp = data.chat_response.split("Context:")[0].trim();
      console.log(resp);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: resp, sender: "llm" },
      ]);
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      style={{
        minHeight: "100vh",
        width: "80%",
        margin: "0 auto",
        marginTop: "40px",
      }}
    >
      <Grid item xs={12}>
        <Paper elevation={1} style={{ height: "80vh", overflow: "auto" }}>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                padding: "8px",
                margin: "8px 0",
                textAlign: message.sender === "user" ? "right" : "left",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  backgroundColor:
                    message.sender === "user" ? "#007bff" : "#9933ff",
                  color: "#fff",
                  padding: "8px",
                  borderRadius: "12px",
                }}
              >
                {message.text}
              </span>
            </div>
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSend}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chat;
