import React, { useState } from "react";
import { Button, Container, Grid, LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PageContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(10),
}));

const StyledButton = styled(Button)({
  backgroundColor: "purple",
  color: "white",
  borderRadius: "10px",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "darkpurple",
  },
  margin: "10px",
});

function WelcomePage() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleNewChatClick = () => {
    navigate("/new_chat");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedName(file ? file.name : "");
  };

  const handleUploadClick = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("username", "admin");
    formData.append("pdfs", selectedFile);

    try {
      const response = await axios.post(
        "https://api.elisiq.com/f-upload-pdfs",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("File uploaded successfully.");
      } else {
        alert("Failed to upload file.");
      }
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Failed to upload file.");
    } finally {
      setUploadProgress(0);
    }
  };

  return (
    <PageContainer maxWidth="lg" style={{ marginBottom: "10px" }}>
      <Grid container spacing={3}>
        <h1>Welcome To PDF Chat App</h1>
        <Grid item xs={12}>
          <StyledButton variant="contained" onClick={handleNewChatClick}>
            New Chat
          </StyledButton>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="pdf-upload"
          />
          <label htmlFor="pdf-upload">
            <StyledButton
              variant="contained"
              component="span"
              disabled={selectedName}
            >
              {selectedName || "Select PDF"}
            </StyledButton>
          </label>
          <StyledButton
            variant="contained"
            onClick={handleUploadClick}
            disabled={!selectedName}
          >
            Upload
          </StyledButton>
          {uploadProgress > 0 && (
            <div>
              <h4>Upload Progress: {uploadProgress}%</h4>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                thickness={4}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
}

export default React.memo(WelcomePage);
