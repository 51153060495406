import React from "react";
import Navbar from "./../Navbar";
// import SideBar from './../SideBar';
import WelcomeScreen from "../WelcomeScreen";

const Dashboard = () => {
  return (
    <>
      <Navbar />
      {/* <SideBar /> */}
      <WelcomeScreen />
    </>
  );
};

export default Dashboard;
