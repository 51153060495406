import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Navbar";
// import Sidebar from './components/SideBar';

import UserContext from "./scripts/userContext";
import PrivateRoute from "./scripts/privateRoute";
import LoginRoute from "./scripts/loginRoute";
import SignUpSide from "./components/SignUp";
import Chat from "./components/Chat";

const hideDashboardComponentRoutes = [
  "/",
  "/view_endpoints",
  "/dashboard",
  "/login",
  "/signup",
];

function App() {
  const [currentUser, setCurrentUser] = React.useState(() => {
    const storedUser = sessionStorage.getItem("pdf_user_data");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [lastActivityTimestamp, setLastActivityTimestamp] = React.useState(
    Date.now()
  );

  const location = useLocation();
  const shouldRenderDashboardComponents =
    !hideDashboardComponentRoutes.includes(location.pathname);

  const resetCurrentUser = (message) => {
    setCurrentUser(null);
    sessionStorage.removeItem("pdf_user_data");
    setLastActivityTimestamp(Date.now());
  };

  const _setUser = (userData) => {
    setCurrentUser(userData);
    sessionStorage.setItem("pdf_user_data", JSON.stringify(userData));
  };

  React.useEffect(() => {
    const inactivityTimeout = setTimeout(() => {
      resetCurrentUser("User inactive for 5 minute");
    }, 600000);

    const resetTimerOnActivity = () => {
      setLastActivityTimestamp(Date.now());
    };

    document.addEventListener("mousemove", resetTimerOnActivity);
    document.addEventListener("keydown", resetTimerOnActivity);

    return () => {
      clearTimeout(inactivityTimeout);
      document.removeEventListener("mousemove", resetTimerOnActivity);
      document.removeEventListener("keydown", resetTimerOnActivity);
    };
  }, [lastActivityTimestamp]);

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        resetUser: resetCurrentUser,
        setUser: _setUser,
      }}
    >
      {shouldRenderDashboardComponents && <Navbar />}
      {/* {shouldRenderDashboardComponents && <Sidebar />} */}
      <Routes>
        <Route path="/" element={<LoginRoute />}>
          <Route index element={<Login />} />
        </Route>

        <Route path="/login" element={<LoginRoute />}>
          <Route index element={<Login />} />
        </Route>

        <Route path="/signup" element={<LoginRoute />}>
          <Route index element={<SignUpSide />} />
        </Route>

        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route index element={<Dashboard />} />
        </Route>

        <Route path="/new_chat" element={<PrivateRoute />}>
          <Route index element={<Chat />} />
        </Route>

        <Route path="*?" element={<Navigate to="/dashboard" />} />
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
